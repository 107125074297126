<template>
  <div class="view-alt-advice container">
    <div>
      <div
        class="edvise-page-title"
      > {{ $t('altAdvicesTitle') }} </div>
      <div class="advice-container">
        <AltAdvicesHtml />
      </div>
    </div>
  </div></template>
<script>
import AltAdvicesHtml from './AltAdvicesHtml';

export default {
  name: 'ViewAltAdvice',
  components: {
    AltAdvicesHtml
  }
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/style.scss';

.container {
  margin: 0 auto;
  padding: 4rem 0rem 0 0rem;
  width: 67%;
  font-family: $font-main;
  position: relative;
}

.view-alt-advice .advice-container {
  max-height: calc(100vh - 19vh - 140px);
  @media (max-width: 1366px) {
    max-height: calc(100vh - 27vh - 140px);
  }
}
.advices-li {
  font-size: 16px;
  letter-spacing: normal;
  text-align: right;
  padding: 1rem 1rem 0 0;
  color: $color-blue;
  direction: rtl;
}
.clean-link {
  text-decoration: none;
  color: $color-blue;
}

.edvise-page-title {
  font-size: 40px;
  color: $color-blue;
  padding: 0rem 0rem 2rem 0rem;
  margin: 0;
  text-align: right;
}

.btns-container {
  margin-top: 3em;

  .btn {
    border-color: $color-blue;
    text-decoration: none;

    &.edit-btn {
      display: flex;
      justify-content: center;
    }
  }
}

.space-filler {
  width: -webkit-fill-available;
}

.nav-next {
  position: fixed;
  right: calc(53% - 9px);
  bottom: 75px;
  cursor: pointer;
  img {
    width: 18px;
    height: 10px;
  }
}

.go-to-advice-btn {
  width: 170px;
}

.advice-form-bottom {
  width: 43vw;
  right: 42vw;
  position: fixed;
  bottom: 27vh;

  @media (min-width: 1468px) {
    width: 22vw;
  }
}

.animation {
  height: 0px;
}

.end-update-process {
  direction: rtl;
  text-align: right;
}

.no-advices {
  text-align: right;
}

.advice-container {
  font-size: 16px;
  padding-right: 25px;
  max-height: calc(100vh - 27vh - 140px);
  &.smaller {
    max-height: calc(100vh - 27vh - 70px - 70px - 30px - 13vh);
  }
  overflow: auto;
  position: absolute;
  right: -30px;

  ol {
    padding-left: 0;
    padding-right: 45px;
  }
}
</style>
