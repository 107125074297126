<template>
  <div class="question-line">
    <span v-html="$t('profileFormQ.' + questionCode)" />
    <span
      v-if="tooltip"
      class="qMark-wrapper"
    >
      <img
        src="../../assets/img/glyphicons/help.png"
        alt="help"
        class="qMark"
        @mouseenter="hover"
      >
      <span
        ref="tooltip"
        class="tooltip"
      >  
        {{ $t('tooltips.' + questionCode) }}

      </span>
    </span>
    <span class="answer">
      <!-- only show answer if there is one and on view mode -->
      {{ answerFromStore && mode === 'view' ? $t('profileFormAnswers.' + answerFromStore) : '' }}
    </span>
    <span v-if="devMode"> <br> {{ 'DEV MODE! questions code:  ' + questionCode + '  ' }} </span>
  </div>
</template>

<script>
import devConfig from '@/config/dev-config';

export default {
  props: {
    questionCode: {
      type: String,
      required: true
    },
    tooltip: {
      type: Boolean,
      default: false
    },
    answerFromStore: {
      type: String,
      default: null
    },
    mode: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      devConfig
    };
  },
  computed: {
    devMode() {
      return this.$store.getters.getDevMode;
    }
  },
  methods: {
    hover(e) {
      let pos = e.target.getBoundingClientRect();
      this.$refs.tooltip.setAttribute(
        'style',
        `top: ${pos.top + pos.height + 5}px; left: ${pos.left -
          5 -
          this.$refs.tooltip.offsetWidth / 2}px;`
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/style.scss';

.question-line {
  direction: rtl;

  img {
    margin-right: 0.5em;
    width: 11px;
    display: inline;
  }

  span.answer {
    float: left;
  }

  .qMark-wrapper {
    position: relative;
  }

  .qMark:hover ~ .tooltip,
  .tooltip:hover {
    max-height: 30vh;
    padding: 8px;
    opacity: 1;
    transition: all 0.25s ease-in;
  }

  .tooltip {
    position: fixed;
    font-size: 16px;
    max-height: 0px;
    opacity: 0;
    z-index: 1;
    transition: all 0.15s ease-out;
    overflow-x: hidden;
    overflow-y: auto;
    color: $color-peach;
    max-width: 450px;
    padding: 0px;
    background-color: $color-blue;
    border-radius: 5px;
  }
}
</style>
