<template>
  <div class="styled-radio-answers">
    <span class="answers-wrapper flex flex-row ">
      <div
        v-for="answer of answers"
        :key="answer.labelCode"
        class="answer-div"
        :class="{selected: selectedAnswerValue === answer.labelCode, 'smaller': Number($route.query.section) === 10, pointer: !isViewMode}"
        @click="selectedAnswer(answer)"
      >
        {{ $t('profileFormAnswers.' + answer.labelCode) }}
      </div>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    answers: {
      // [{ labelCode }]
      type: Array,
      required: true
    },
    answerFromStore: {
      type: String,
      default: null
    },
    isViewMode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedAnswerValue: this.answerFromStore
    };
  },
  methods: {
    selectedAnswer(answer) {
      if (!this.isViewMode) {
        this.selectedAnswerValue = answer.labelCode;
        this.$emit('answer-selected', answer.labelCode);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/style.scss';

.styled-radio-answers {
  text-align: -webkit-right;
}

.answers-wrapper {
  direction: rtl;
  width: fit-content;
  background-color: #fff;

  .answer-div {
    padding: 2px 2em;
    &.smaller {
      padding: 2px 1em;
    }
    margin: 10px;
    transition: 0.3s background-color;

    &.selected {
      background-color: $color-peach;
    }
  }
}

.pointer {
  cursor: pointer;
}
</style>
