<template lang="pug">
  .container
    div
      .save-success(v-if="$route.query.saveSuccess")
        div {{ $t('saveSuccessfully1') }}
        div(style="font-size: 20px;") {{ $t('saveSuccessfully2') }}
      .selected-count
        div {{selectedAdvise.length}} המלצות נבחרו
        div
          a(href="javascript:void(0)" @click="downloadAdvises") הורדת ההמלצות
      .edvise-page-title {{ $t(`advice.${getPageTitle}`) }}
      .no-advices(v-if="filteredAdvices.length < 1", dir="rtl") {{ $t('emptyAdvices[0]') }}

        br

        | {{ $t('emptyAdvices[1]') }}

        router-link(to="/teacher/search-student/view-profile-form?section=0") {{ $t('clickHere') }}
      .advice-container(v-if="filteredAdvices.length > 0 && currentUrl !== 'adminComments'", :class="{'smaller': isAdmin}")
        ol
          li(v-if="devMode") DEV ONLY:
            br

            | {{ adviceCodes }}
          li.advices-li(v-for="(advice, index) of filteredAdvices", :key="index")
            span(v-if="devMode") DEV ONLY: advice number
              | {{ advice.adviceCode }}
              br
            input(type="checkbox", :value="advice.adviceCode", v-model="selectedAdvise")
            span {{ advice.adviceText }}.
            span(v-if="isAdmin") קוד עצה:
              | {{ advice.adviceCode }}
            span.qMark-wrapper(v-if="advicesTolltipsJson.includes(Number(advice.adviceCode))")
              img.qMark(src="@/assets/img/glyphicons/help.png", alt="help", @mouseenter="hover($event, advice.adviceCode)")
              span(:class="'tooltip tooltip' + advice.adviceCode") {{ $t('advicesTooltips.' + advice.adviceCode) }}
        .advice-form-bottom(v-if="isAdmin")
          .btns-container.flex.flex-row.space-between
            .round-btn-transparent.btn.go-to-advice-btn
              router-link.clean-link(to="/admin/edit-advice") כתבי המלצות חדשות
            .round-btn-blue.btn.go-to-advice-btn(@click="approveAdvice") שלחי המלצות אלה
      .admin-comments(v-if="currentUrl === 'adminComments'") {{ filteredAdvices[0] }}
    .nav-next(v-if="filteredAdvices.length > 0 && !lastSection", @click="nextAdviceTopic")
      div(style="padding-bottom: 5px;") להמלצה הבאה
      img(src="@/assets/img/glyphicons/next.png")

</template>
<script>
import advices from '@/config/advices.json5';
import adminService from '@/services/admin-service';
import advicesTopicListMixins from '@/mixins/advices-topic-list-mixins';
import advicesTolltipsJson from '@/config/advices-tooltips.json5';
import devConfig from '@/config/dev-config';
import axios from "axios";
import JSZip from "jszip";
import $ from "jquery";

export default {
  name: 'ViewAdvice',
  mixins: [advicesTopicListMixins],
  data() {
    return {
      devConfig,
      advices,
      filteredAdvices: [],
      currentUrl: '',
      lastSection: false,
      advicesTolltipsJson,
      selectedAdvise: []
    };
  },
  computed: {
    adviceCodes() {
      let student = this.$store.getters['teacher/getSelectedStudent'];
      if (student.recommendationsData) {
        return student.recommendationsData.recommendationCodes;
      }
      return [];
    },
    isAdmin() {
      return this.$store.getters['teacher/isSysAdmin'];
    },
    devMode() {
      return this.$store.getters.getDevMode;
    },
    getPageTitle() {
      return this.$route.query.section || 'behavior';
    }
  },
  watch: {
    $route() {
      // console.log('route change');
      this.filteredAdvices = [];
      this.createFilterList();
      this.manageLastSection();
    },
    selectedAdvise(newVal){
      const student = this.$store.getters['teacher/getSelectedStudent']
      if(newVal.length !== student.recommendationsData.selected.length){
        this.updateSelected()
      }
    }
  },
  mounted() {
    this.createFilterList();
    this.manageLastSection();
    let student = this.$store.getters['teacher/getSelectedStudent']
    if(student.recommendationsData){
      this.selectedAdvise = student.recommendationsData.selected
    }
  },
  methods: {
    async updateSelected(){
      let res = await this.$store.dispatch(
          'teacher/updateSelectedRecommendations',
          {
            selected: this.selectedAdvise,
            token: this.$store.getters.getToken
          }
      );
      if (res) {
        this.selectedAdvise = res.selected
        // console.log({ res: res.data });
      }
    },
    getAdvisesExport(){
      const advicesCodes = this.selectedAdvise
      let replaceList = {}
      for(let category of Object.values(advices)){
        const found = Object.keys(category).filter(x=>advicesCodes.includes(parseInt(x)))
        const selected = Object.entries(category)
            .map(x=>found.includes(x[0]) ? x[1] : '')
            .filter(x=>!!x)
        if(selected.length) replaceList[category.t] = selected
      }
      return replaceList
    },
    async getTemplate(){
      const res = await axios.get('/recommendation.docx', { responseType: 'blob' })
      return await JSZip.loadAsync(res.data)
    },
    replaceText(doc, replace){
      let headerP = $(doc).find(`w\\:p:contains('קטגוריה')`)
      let p = $(doc).find(`w\\:p:contains('המלצה')`)
      let last = p

      for(let header in replace){
        let newH = headerP.clone()
        newH.find('w\\:t').text(header)
        last.after(newH)
        last = newH
        for(let rcmd of replace[header]){
          let row = p.clone()
          row.find('w\\:t').text(rcmd)
          last.after(row)
          last = row
        }
      }
      headerP.remove()
      p.remove()
      return doc
    },
    async saveFile(file, fileName){
      const blob = await file.generateAsync({
        type: "blob",
        compression: "DEFLATE"
      })
      const a = document.createElement('a');
      const blobURL = window.URL.createObjectURL(blob)
      const tempLink = document.createElement('a');
      tempLink.style.display = 'none';
      tempLink.href = blobURL;
      tempLink.setAttribute('download', fileName);
      document.body.appendChild(tempLink);
      tempLink.click();
      document.body.removeChild(tempLink);
    },
    async downloadAdvises(){
      const file = await this.getTemplate()
      const student = this.$store.getters['teacher/getSelectedStudent']
      const xml = await file.file('word/document.xml').async("string")
      let doc = $.parseXML(xml)

      let replace = this.getAdvisesExport()

      $(doc).find(`w\\:p:contains('ייתלמידיי')`).find('w\\:t').text(`תלמיד: ${student.lastName} ${student.firstName}`)
      $(doc).find(`w\\:p:contains('ייתזיי')`).find('w\\:t').text(`ת.ז: ${student.studentTz}`)
      $(doc).find(`w\\:p:contains('ייתאריךיי')`).find('w\\:t').text(`תאריך: ${new Date().toJSON().slice(0,10).split('-').reverse().join('/')}`)
      $(doc).find(`w\\:p:contains('ייכיתהיי')`).find('w\\:t').text(`כיתה: ${student.schoolClass.classGrade} ${student.schoolClass.classNumber}`)
      doc = this.replaceText(doc, replace)

      const serializer = new XMLSerializer();
      const newXML = serializer.serializeToString(doc)
      file.file('word/document.xml', newXML)

      let fn = ''
      if(student.firstName && student.lastName){
        fn = student.lastName +'-'+ student.firstName
      }else{
        fn = student.studentTz
      }

      await this.saveFile(file, `המלצות-${fn}.docx`)
    },


    createFilterList() {
      this.currentUrl = this.$route.query.section;
      if (this.currentUrl === 'adminComments') {
        this.filteredAdvicesAdminComments();
        return;
      }
      const advicesTopicList = this.advicesTopicList();
      if (!advicesTopicList.includes(this.currentUrl)) {
        // if the current url isn't an  adivce topic, for example 459343,
        // make the current advices topic the first one in the list
        this.currentUrl = advicesTopicList[0];
        this.$route.query.section = this.currentUrl;
      }
      this.handleFilteredAdvices();
    },
    handleFilteredAdvices() {
      if (this.currentUrl === 'adminComments') {
        this.filteredAdvicesAdminComments();
        return;
      }
      let studentCurrentAdvices = this.adviceCodes;
      let advicesObj = this.advices[this.currentUrl];
      for (const studentAdvice of studentCurrentAdvices) {
        if (advicesObj[studentAdvice]) {
          this.filteredAdvices.push({
            adviceText: advicesObj[studentAdvice],
            adviceCode: studentAdvice
          });
        }
      }
      // console.log({ filteredAdvices: this.filteredAdvices });
    },
    manageLastSection() {
      const advicesTopicList = this.advicesTopicList();
      const section = this.$route.query.section;
      const sectionIndex = advicesTopicList.findIndex(
        sectionInStudentAdvices => sectionInStudentAdvices === section
      );
      const nextSection = advicesTopicList[sectionIndex + 1];
      this.lastSection = false;
      if (!nextSection) {
        this.lastSection = true;
      }
      return nextSection;
    },
    nextAdviceTopic() {
      const nextSection = this.manageLastSection();
      if (nextSection) {
        const path = this.$route.path;
        this.$router.push(path + '?section=' + nextSection);
      }
    },
    async approveAdvice() {
      let res = await adminService.approveAdvices(
        this.$store.getters.getToken,
        this.$store.getters['teacher/getSelectedStudent']._id,
        this.$store.getters['teacher/getSearchedTz']
      );
      if (res) {
        if (res.data) {
          if (res.data.success) {
            this.$router.push('/admin/finish');
          }
        }
      }
    },
    filteredAdvicesAdminComments() {
      this.filteredAdvices = [
        this.$store.getters['teacher/getSelectedStudent'].recommendationsData
          .adminComments
      ];
    },
    hover(e, adviceCode) {
      const pos = e.target.getBoundingClientRect();
      const el = document.querySelector('.tooltip' + adviceCode);
      el.setAttribute(
        'style',
        `top: ${pos.top + pos.height + 5}px; left: ${pos.left -
          5 -
          el.offsetWidth / 2}px;`
      );
    }
  }
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/style.scss';

// tooltip css

.qMark-wrapper {
  position: relative;
}

.qMark:hover ~ .tooltip,
.tooltip:hover {
  // display: inline;
  max-height: 30vh;
  opacity: 1;
  padding: 8px;
  transition: all 0.25s ease-in;
}

.tooltip {
  position: fixed;
  // display: none;
  font-size: 16px;
  max-height: 0px;
  opacity: 0;
  z-index: 1;
  transition: all 0.15s ease-out;
  overflow-x: hidden;
  overflow-y: auto;
  color: $color-peach;
  max-width: 450px;
  padding: 0px;
  background-color: $color-blue;
  // top: 15px;
  // left: -225px;
  border-radius: 5px;
}

.qMark {
  margin-right: 0.5em;
  width: 11px;
  display: inline;
}

.container {
  margin: 0 auto;
  padding: 4rem 0rem 0 0rem;
  width: 67%;
  font-family: $font-main;
  position: relative;
}

.clean-link {
  text-decoration: none;
  color: $color-blue;
}

.save-success{
  direction: rtl;
  font-weight: 700;
  border-radius: 25px;
  text-align: center;
  padding: 3px 10px;
  background: $color-blue;
  color: white;
  width: 300px;
  margin: auto;
}

.edvise-page-title {
  font-size: 40px;
  color: $color-blue;
  padding: 0rem 0rem 2rem 0rem;
  margin: 0;
  text-align: right;
}

.btns-container {
  margin-top: 3em;

  .btn {
    border-color: $color-blue;
    text-decoration: none;

    &.edit-btn {
      display: flex;
      justify-content: center;
    }
  }
}

.space-filler {
  width: -webkit-fill-available;
}

.nav-next {
  position: fixed;
  right: calc(53% - 9px);
  bottom: 75px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    width: 18px;
    height: 10px;
  }
}

.go-to-advice-btn {
  width: 170px;
}

.advice-form-bottom {
  width: 43vw;
  right: 42vw;
  position: fixed;
  bottom: 27vh;

  @media (min-width: 1468px) {
    width: 22vw;
  }
}

.animation {
  height: 0px;
}

.end-update-process {
  direction: rtl;
  text-align: right;
}

.no-advices {
  text-align: right;

  a {
    color: #2c3e50;
  }
}

.selected-count{
  direction: rtl;
  position: absolute;
  text-align: left;
}

.advice-container {
  max-height: calc(100vh - 27vh - 140px);
  &.smaller {
    max-height: calc(100vh - 27vh - 70px - 70px - 30px - 13vh);
  }
  overflow: auto;
  position: absolute;
  right: -30px;

  ol {
    padding-left: 0;
    padding-right: 20px;
    list-style-type: none;

    .advices-li {
      input{
        vertical-align: middle;
        margin-left: 10px;
      }
      font-size: 16px;
      letter-spacing: normal;
      text-align: right;
      padding: 1rem 1rem 0 0;
      color: $color-blue;
      direction: rtl;
    }
  }
}

.admin-comments {
  text-align: right;
}
</style>
