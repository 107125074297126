<template>
  <div class="profile-form flex flex-column">
    <div class="profile-form-title">{{ $t('profile.' + section) }}</div>
    <div
      ref="profileFormSection"
      class="profile-form-container"
    >
      <div class="profile-form-content">
        <div
          v-if="profileFormJson[section]"
          class="list-wrapper"
        >
          <div
            v-for="(qObj, qCode) in questionsObj"
            :key="qCode"
          >
            <div v-if="!filterQuestions(qCode)">
              <div class="question-line-wrapper">
                <QuestionLine
                  :question-code="qCode"
                  :answer-from-store="getAnswersAboutStudent(qCode)"
                  :mode="mode"
                  :tooltip="qObj.tooltip"
                />
              </div>
              <div v-if="mode === 'edit'">
                <StyledRadioAnswers
                  :answers="questionsObj[qCode].answers"
                  :answer-from-store="getAnswersAboutStudent(qCode)"
                  @answer-selected="updateAnswers($event, qCode)"
                />
              </div>
              <div class="hr" />
            </div>
          </div>
        </div>
        <div v-if="section === 'finish'">
          <ProfileFormFinish
            ref="finish"
            :mode="mode"
          />
          <div class="hr" />
        </div>
      </div>
    </div>
    <div class="profile-form-bottom">
      <div class="btns-container flex flex-row space-between">
        <div v-if="mode === 'view' && !isAdmin">
          <router-link
            tag="button"
            :to="'/teacher/search-student/edit-profile-form?section=' + section"
            class="edit-btn btn round-btn-transparent"
          >
            {{ $t('editBtn') }}
            <img
              src="@/assets/img/glyphicons/edit-blue.png"
              class="pencil"
            >
          </router-link>
        </div>
        <div class="space-filler" />
        <div
          v-if="mode === 'edit'"
          class="route-btns-wrapper flex flex-row"
        >
          <div
            v-if="section === 'finish'"
            class="round-btn-blue btn send-btn"
            :class="{ 'disabled-btn': !formComplete }"
            @click="submitForm"
          >{{ $t('send') }}</div>
          <div
            v-if="section !== 'finish'"
            class="round-btn-blue btn next"
            @click="navigateToSection(1)"
          >{{ $t('next') }}</div>
          <div
            v-if="sectionIndex > 0 || section === 'finish'"
            class="round-btn-transparent btn previous"
            @click="navigateToSection(-1)"
          >{{ $t('previous') }}</div>
        </div>
        <router-link
          v-if="section === 'finish' && mode === 'view'"
          tag="div"
          :to="`${relativePath}/view-advice`"
          class="round-btn-blue btn go-to-advice-btn"
        >{{ $t('goToAdvice') }}</router-link>
      </div>
      <div
        v-if="mode === 'view' && section != 'finish'"
        class="nav-next"
        @click="navigateToSection(1)"
      >
        <img src="@/assets/img/glyphicons/next.png">
      </div>
    </div>
  </div>
</template>

<script>
import profileFormJson from '@/config/profile-form.json5';
import hiddenWhenStudNotDiagnosed from '@/config/hidden-when-stud-not-diagnosed.json5';
import teacherService from '@/services/teacher-service';
import ProfileFormFinish from './ProfileFormFinish';
import QuestionLine from './QuestionLine';
import StyledRadioAnswers from './StyledRadioAnswers';
import relativePathMixins from '@/mixins/relative-path-mixins';
import constants from '@/constants/app-constants';

export default {
  name: 'ProfileForm',
  components: {
    QuestionLine,
    StyledRadioAnswers,
    ProfileFormFinish
  },
  mixins: [relativePathMixins],
  props: {
    mode: {
      type: String,
      default: 'view'
    }
  },
  data() {
    return {
      section: null,
      profileFormJson,
      questionsObj: {},
      answersObj: {},
      hiddenWhenStudNotDiagnosed
    };
  },
  computed: {
    jsonKeysArr() {
      let jsonKeysArr = Object.keys(profileFormJson);
      if (!this.$store.getters['teacher/isAllQuestionsDisplayed']) {
        // if showing partial questions form, keys arr is different.
        jsonKeysArr =
          hiddenWhenStudNotDiagnosed.sectionsToShowForIncompleteForm;
      }
      return jsonKeysArr;
    },
    sectionIndex() {
      let sectionIndex = this.jsonKeysArr.findIndex(
        section => section == this.section
      );
      return sectionIndex;
    },
    formComplete() {
      return this.$store.getters['teacher/getIsFormFullyFilledByUser'];
    },
    isAdmin() {
      return this.$store.getters['teacher/isSysAdmin'];
    }
  },
  async beforeRouteLeave(to, from, next) {
    await this.handleRouteLeave(to, from);
    next();
  },
  watch: {
    async $route(newRoute, oldRoute) {
      this.handleRouteLeave(newRoute, oldRoute);
    },
    mode(val) {
      this.mode = val;
    }
  },
  created() {
    this.section = this.$route.query.section || 0;
    this.setComponentData();
    this.manageRelativePath();
  },
  methods: {
    async handleRouteLeave(newRoute, oldRoute) {
      // reset the scroll
      this.$refs.profileFormSection.scroll(0, 0);
      // redfine section to new one
      this.section = this.$route.query.section;
      if (this.mode === 'edit') {
        // if edit mode, check if the sction of the form is complete.
        this.setTreesFullyFilledByUser();
        if (oldRoute.query.section === 'finish') {
          // teacher comments has a different logic. update it in db if the user is leaving its section (finish)
          this.$refs.finish.updateComments();
        }
        let res = await this.$store.dispatch(
          'teacher/updateAnswersAboutStudent',
          this.$store.getters.getToken
        );
        this.setComponentData();
        // debug
        if (res) {
          // console.log({ res: res.data });
        }
      } else {
        this.setComponentData();
      }
    },
    filterQuestions(qCode) {
      if (
        !this.$store.getters['teacher/isAllQuestionsDisplayed'] &&
        hiddenWhenStudNotDiagnosed.hiddenQuestions.includes(qCode)
      ) {
        return true;
      }
      return false;
    },
    setTreesFullyFilledByUser() {
      let formComplete = true;
      for (const qCode in this.questionsObj) {
        if (!this.answersObj[qCode]) {
          formComplete = false;
        }
      }
      if (formComplete) {
        this.$store.commit('teacher/setTreesFullyFilledByUser', this.section);
      }
    },
    setComponentData() {
      this.questionsObj = profileFormJson[this.section];
      this.setAnswersObj();
    },
    async navigateToSection(direction) {
      this.$router.push(
        this.$route.path + '?section=' + this.calcSection(direction)
      );
    },
    calcSection(direction) {
      // direction: number, 1 is next, -1 is previous
      if (this.section === 'finish') {
        // in finish section, its only possible to navigate backwards
        return this.jsonKeysArr[this.jsonKeysArr.length - 1];
      }
      let sectionIndex = Number(this.sectionIndex);
      // sectionIndex is 0 and direction is -1 - this should not happen. there is not section -1
      if ((sectionIndex === 0 && direction > 0) || sectionIndex >= 1) {
        let nextSection = this.jsonKeysArr[sectionIndex + direction];
        if (!this.$store.getters['teacher/isAllQuestionsDisplayed']) {
          nextSection =
            hiddenWhenStudNotDiagnosed.sectionsToShowForIncompleteForm[
              sectionIndex + direction
            ];
        }
        if (nextSection) {
          return nextSection;
        }
      }
      return 'finish';
    },
    updateAnswers(answerCode, qCode) {
      if (qCode === 'IsStudDiagnosed') {
        this.$store.commit(
          'teacher/setIsAllQuestionsDisplayed',
          answerCode === constants.answers.YES
        );
      }
      this.answersObj[qCode] = answerCode;
      this.$store.commit('teacher/setTreeAnswersAboutStudent', {
        treeId: this.section,
        answersObj: this.answersObj
      });
    },
    getAnswersAboutStudent(qCode) {
      if (this.answersObj[qCode]) {
        return this.answersObj[qCode];
      }
      return '';
    },
    async submitForm() {
      if (!this.formComplete) {
        // console.warn('form not complete');
        return;
      }
      const data = {
        studentObjId: this.$store.getters['teacher/getSelectedStudent']._id,
        teacherComment: this.$refs.finish.commentText
      };
      let res = await teacherService.submitProfileForm(
        data,
        this.$store.getters.getToken,
        this.$store.getters['teacher/getSearchedTz']
      );
      if (res) {
        if (res.data) {
          if (res.data.success) {
            this.$store.commit('teacher/setSubmitSuccess', true);
            this.$store.commit('teacher/setSelectedStudent', res.data.student);
            this.$router.push(
              '/teacher/search-student/view-advice?saveSuccess=true'
            );
          }
        }
      }
    },
    setAnswersObj() {
      // get the answers about student from student in store and update the component
      this.answersObj = {};
      let answersAboutStudentObj = this.$store.getters[
        'teacher/getAnswersAboutStudentObj'
      ];
      // find relevant questions tree to the route query (this.section)
      let answersTree = answersAboutStudentObj[this.section];
      if (answersTree) {
        this.answersObj = answersTree;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/style.scss';

.profile-form {
  padding-top: 5em;
  @media (max-height: 700px) {
    padding-top: 1em;
  }
  position: relative;

  .profile-form-container {
    z-index: 1;
    width: 67%;
    margin: 0 auto;
    padding-right: 2%;
    text-align: right;
    height: calc(100vh - (#{$header} + #{$header} + 100px + 40px + 5em + 2em));
    @media (max-height: 700px) {
      height: calc(
        100vh - (#{$header} + #{$header} + 100px + 40px + 1em + 2em)
      );
    }
    overflow: auto;
    transition: height 0.3s;
  }

  .profile-form-title {
    font-size: 40px;
    padding-bottom: 1em;
    font-weight: 600;
    color: $color-blue;
    width: max-content;
    margin-left: auto;
    margin-right: 18.5%;
  }

  .profile-form-content {
    font-size: 16px;
    color: $color-blue;
  }

  .hr {
    margin-top: 24px;
    margin-bottom: 26px;
    border-bottom: 1px solid black;
  }

  .question-line-wrapper {
    margin-bottom: 5px;
  }

  .btns-container {
    margin-top: 3em;

    .btn {
      border-color: $color-blue;

      &.edit-btn {
        display: flex;
        justify-content: center;
      }
    }
  }

  .route-btns-wrapper {
    margin-right: 30px;

    .next,
    .send-btn,
    .previous {
      width: 110px;
    }

    .previous {
      margin-left: 4em;
    }
  }

  .space-filler {
    width: -webkit-fill-available;
  }

  .nav-next {
    position: absolute;
    right: calc(50% - 9px);
    bottom: 10px;
    cursor: pointer;
    img {
      width: 18px;
      height: 10px;
    }
  }

  .go-to-advice-btn {
    width: 170px;
  }

  .profile-form-bottom {
    width: 43vw;
    right: 42%;
    position: fixed;
    bottom: 70px;
    @media (min-width: 1468px) {
      width: 35vw;
    }
  }
  .animation {
    height: 0px;
  }
}
</style>

<style lang="scss">
@import '@/assets/scss/style.scss';

.profile-form {
  ::-webkit-scrollbar-track {
    background-color: transperent;
  }
  ::-webkit-scrollbar {
    display: block;
    width: 4px;
    background-color: transperent;
  }
  ::-webkit-scrollbar-thumb {
    display: block;
    border-radius: 10px;
    background-color: $color-blue;
  }
}
</style>
