<template lang="pug">
.search-student.flex.justify-center
  .search-student-container
    .search-student-title {{ $t('searchStudentTitle') }}
    .search-student-input-container.placeholder
      input.search-student-input.edvise-input(ref="studentTzInput",
        v-model="studentTz", :placeholder="$t('searchTz')", type="number")
      button.search-btn.round-btn-blue(:class="{disabled:disableBtn}", @click="searchStudent")
        span {{ $t('search') }}

        span
          img(src="@/assets/img/glyphicons/search.png")
    .bad-search-result(v-if="noStudentFound") {{ $t('searchResult[0]') }}
      br
      | {{ $t('searchResult[1]') }}
      router-link(to="/teacher/new-student", tag="span")
        span.underline-text {{ $t('searchResult[2]') }}
        | ?
    .student-table
      .schools(v-if="schools.length > 1")
        .school(v-for="schoolName in schools",
          :class="{active: school===schoolName}",
          @click="filterStudentsList(schoolName)") {{schoolName}}
      table(v-if="studentsList")
        thead
          tr
            th שנת לימוד
            th כיתה
            th שם
            th תז
        tbody
          template(v-for="student in studentsListFiltered" )
            tr(v-if="student.yearSep")
              td(colspan="4", style="text-align: center; font-weight: bold") {{student.year}}
            tr(:key="student.studentTz+student.schoolName", @click="searchStudentFromList(student.studentTz)")
              td {{student.year}}
              td {{student.cls}}
              td {{student.lastName}} {{student.firstName}}
                span.addName(v-if="!student.lastName && !student.firstName",
                  @click.prevent="searchStudentFromList(student.studentTz, true)") הוסף שם...
              td {{student.studentTz}}
</template>

<script>
import teacherService from '@/services/teacher-service';
import moment from 'moment';
import semelMosadList from '@/config/semel-mosad.json5';

export default {
  data() {
    return {
      studentTz: '',
      noStudentFound: null,
      studentsList: false,
      schools: false,
      school: false,
      studentsListFiltered: [],
      studentEdit: false
    };
  },
  computed: {
    disableBtn() {
      return this.studentTz.length < 8;
    }
  },
  mounted() {
    this.$refs.studentTzInput.addEventListener('keypress', event => {
      if (event.keyCode === 13) {
        // on user press enter
        this.searchStudent();
      }
    });
    this.$refs.studentTzInput.focus();
    this.getStudentList()
  },
  methods: {
    searchStudentFromList(studentTz, edit){
      this.disableBtn = false
      this.studentTz = studentTz
      this.studentEdit = edit
      this.searchStudent();
    },
    async searchStudent() {
      try {
        if (this.disableBtn && !studentTz) {
          // if the user is clicking while the btn is disabled, break the function
          return;
        }
        this.noStudentFound = false;
        let res = await teacherService.getStudentByTz(
          this.$store.getters.getToken,
          this.studentTz
        );

        // if any of these conditions is not met, show the user validation error
        if (res) {
          if (res.data) {
            if (res.data.success && res.data.student) {
              this.$store.commit('teacher/setSearchedTz', this.studentTz);
              this.$store.commit(
                'teacher/setSelectedStudent',
                res.data.student
              );
              if (this.$store.getters.devMode) {
                // console.log('student:', res.data.student);
              }
              if(this.studentEdit){
                return this.$router.push(
                    '/teacher/search-student/edit-student'
                );
              }
              if (res.data.student.formData) {
                // if the for, was submitted, navigate the user to advices
                if (res.data.student.formData.isSubmitted) {
                  return this.$router.push(
                    '/teacher/search-student/view-advice'
                  );
                }
              }
              return this.$router.push(
                '/teacher/search-student/view-profile-form?section=0'
              );
            }
          }
        }

        // this is for validation - in case no student was found
        this.noStudentFound = true;
        return;

        // console.log(res.data.student);
      } catch (err) {
        console.warn(err);
      }
    },
    findSchoolName(semelMosad) {
      const school = semelMosadList.find(
          schoolObj => schoolObj.semelMosad == semelMosad
      );
      return school ? school.schoolName : semelMosad;
    },
    getYear(date){
      date = moment(new Date(date))
      if (date.isBefore(date.year()+'-07-01'))
        return `${date.year()-1}-${date.year()}`
      return `${date.year()}-${date.year()+1}`
    },
    async getStudentList(){
      let res = await teacherService.getStudentsList(
          this.$store.getters.getToken
      );
      if(res){
        let studentsList = res.data.list
        studentsList = studentsList.map(x=>{
          x.year = this.getYear(x.createdAt)
          x.schoolName = this.findSchoolName(x.semelMosad)
          return x
        })

        let schools = [...new Set(studentsList.map(x=>x.schoolName))]
        this.schools = schools

        const sortBy = (arg, dir)=>{
          dir = dir === 'desc' ? -1 : 1
          return (x,y)=>{
            if(x[arg] > y[arg]) return 1*dir
            else if(x[arg] === y[arg]) return 0
            return -1*dir
          }
        }
        studentsList = studentsList
            .sort(sortBy('lastName'))
            .sort(sortBy('cls'))
            .sort(sortBy('year','desc'))
            .sort(sortBy('school'))

        this.studentsList = studentsList
        this.filterStudentsList(schools[0])
      }
    },
    filterStudentsList(school){
      this.school = school
      let studentsListFiltered = this.studentsList.filter(x=>x.schoolName===this.school)
      let last = false
      for(let student of studentsListFiltered){
        if (student.year !== last) student.yearSep = true
        last = student.year
      }
      console.log(school,studentsListFiltered)
      this.studentsListFiltered = studentsListFiltered
    }
  }
};
</script>


<style lang="scss" scoped>
@import '@/assets/scss/style.scss';

.search-student {
  padding-top: 3em;

  .search-student-container {
    width: 65%;
    text-align: right;
    direction: rtl;
  }

  .search-student-input-container {
    display: flex;
    justify-content: space-between;
  }

  .search-student-input {
    font-size: 16px;
    width: calc(100% - 110px - 15px);
    color: $color-blue;
  }

  .search-btn {
    height: 36px;
    width: 110px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    outline: none;
    align-items: center;

    &.disabled {
      // filter: grayscale(1);
      background-color: $color-lightblue;
      cursor: auto;
    }

    img {
      height: 11px;
      margin-left: 4px;
    }
  }

  .bad-search-result {
    padding-top: 3em;
    font-size: 15px;
    color: $color-blue;
  }

  .underline-text {
    text-decoration: underline;
    cursor: pointer;
  }

  .search-student-title {
    margin-bottom: .5em;
    font-size: 40px;
    // padding-bottom: 1em;
    font-weight: 600;
    color: $color-blue;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
}
.student-table{
  overflow: auto;
  height: available;
  margin-top: .5em;
  font-size: 1.2em;
  height: calc(100vh - 56px - 36px - 3em - 50px - .5em - 36px);
  table{
    border-collapse: collapse;
    width: 100%;
  }
  thead tr{
    border-bottom: 1px black solid;
  }
  tr:nth-child(even) {
    background: #ddd
  }
  tbody tr:hover{
    background: #ccc;
    cursor: pointer;
  }
  td, th {
    padding: 7px 9px;
  }

  .school{
    display: inline-block;
    padding: 5px 10px;
    background: #545871;
    color: #f3d5bd;
    margin: 5px;
    border-radius: 5px;
    &.active{
      color: #fff;
      border: 3px solid #000000;
    }
  }
  .addName{
    color: #888;
    font-style: italic;
  }
}
</style>
