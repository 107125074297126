<template lang="pug">
.register-student
  .form-container.placeholder
    .form-title {{ isEditMode ? $t('editStudent') : $t('addStudent') }}
    .form-subtitle {{ $t('fillAllFields') }}
    .top-marg.fit-content
      .form-subtitle {{ $t('labels.tz') }}
      input.long-selection.edvise-input(v-model="formData.studentTz",
        :placeholder="$t('labels.etzPlaceholder')",
        type="number", :label="$t('labels.tz')",
        :reverse="true",
        :disabled="isEditMode",
        @blur="touched.studentTz = true",
        @input="maxLength('studentTz', 9)")
      .my-validation-text(:class="{'shown': (touched.studentTz || isEditMode) && success === false && err}") {{ $t('errorCodes.' + err ) }}
    .flex(style="margin-bottom: 20px;")
      .form-subtitle {{ $t('labels.firstName') }}
        div
          input.long-selection.edvise-input(v-model="formData.firstName",
            :label="$t('labels.firstName')",
            @blur="touched.firstName = true")
      .right-marg
        .form-subtitle {{ $t('labels.lastName') }}
          div
            input.long-selection.edvise-input(v-model="formData.lastName",
              :label="$t('labels.lastName')",
              @blur="touched.lastName = true")
    .flex
      div(class)
        .form-subtitle {{ $t('labels.semelMosad') }}
        select.long-selection.edvise-input(v-model="formData.semelMosad")
          option(v-for="school in matchingSchools", :value="school.semelMosad") {{school.schoolName || school.semelMosad}}
      .right-marg
        .form-subtitle {{ $t('labels.classGrade') }}
        select.short-selection.edvise-input(v-model="formData.classGrade")
          option(v-for="_class in classGrade", :value="_class.number") {{_class.letter}}
      .right-marg
        .form-subtitle {{ $t('labels.classNumber') }}
        div
          input.short-selection.edvise-input(v-model="formData.classNumber",
            :placeholder="$t('labels.numPlaceholder')",
            type="number", :label="$t('labels.classNumber')",
            @blur="touched.classNumber = true",
            @input="maxLength('classNumber', 3)")
    .top-marg.flex
      span
        button.search-btn.round-btn-blue(:disabled="!isFormValid",
          :class="{ 'disabled': !isFormValid, 'pointer': isFormValid }", @click="registerStudent") {{ $t('save') }}
      span
        button.search-btn.cancel.round-btn-transparent(v-if="isEditMode", @click="$router.back()") {{ $t('cancel') }}

</template>

<script>
// @ is an alias to src
// import CustomSelect from '@/components/CustomSelect';
import teacherService from '@/services/teacher-service';
import dialogMixins from '@/mixins/dialog-mixins';
import relativePathMixins from '@/mixins/relative-path-mixins';
import classGrade from '@/locales/class-grade';
import semelMosadList from '@/config/semel-mosad.json5';
import classGradesJson from "@/locales/class-grade.json";

export default {
  name: 'RegisterStudent',
  components: {  }, //CustomSelect
  mixins: [dialogMixins, relativePathMixins],
  data() {
    return {
      classGrade,
      semelMosadList,
      matchingSchools: [],
      formData: {
        name: null,
        studentTz: null,
        semelMosad: null,
        classGrade: null,
        classNumber: null
      },
      touched: {
        studentTz: false,
        semelMosad: false,
        classNumber: false
      },
      success: null,
      err: null
    };
  },
  computed: {
    isEditMode() {
      return this.relativePath.includes('edit-student');
    },
    isFormValid() {
      return (
        !!this.formData.studentTz &&
        this.formData.studentTz.length >= 8 &&
        !!this.formData.classGrade &&
        !!this.formData.classNumber &&
        !!this.formData.semelMosad
      );
    },
    schoolsList() {
      return this.$store.getters['teacher/getSchoolList'];
    }
  },
  beforeRouteLeave(to, from, next) {
    this.resetForm();
    next();
  },
  watch: {
    formData: {
      handler: function() {
        this.success = null;
        this.err = null;
      },
      deep: true
    }
  },
  mounted() {
    this.currentSchools();
    let editStudentObj = this.$store.getters['teacher/getEditStudent'];
    if(editStudentObj && !editStudentObj.semelMosad){
      const studentDetails = this.$store.getters['teacher/getSelectedStudent'];
      const classGrade = Number(
          classGradesJson.find(
              classGradeObj =>
                  classGradeObj.letter === studentDetails.schoolClass.classGrade
          ).number
      );
      this.$store.commit('teacher/setEditStudent', {
        semelMosad: studentDetails.schoolClass.semelMosad,
        classNumber: studentDetails.schoolClass.classNumber,
        classGrade,
        firstName: studentDetails.firstName,
        lastName: studentDetails.lastName,
        studentObjId: studentDetails._id
      });
      editStudentObj = this.$store.getters['teacher/getEditStudent'];
    }
    if (editStudentObj.semelMosad) {
      this.formData.studentTz = this.$store.getters['teacher/getSearchedTz'];
      this.formData.firstName = editStudentObj.firstName;
      this.formData.lastName = editStudentObj.lastName;
      this.formData.semelMosad = editStudentObj.semelMosad;
      this.formData.classNumber = editStudentObj.classNumber;
      this.formData.classGrade = editStudentObj.classGrade;
    }
  },
  methods: {
    legalTz(num) {
      var tot = 0;
      var tz = String(num).padStart(9,'0');
      for (let i = 0; i < 8; i++) {
        let x = (((i % 2) + 1) * tz.charAt(i));
        if (x > 9) {
          x = x.toString();
          x = parseInt(x.charAt(0)) + parseInt(x.charAt(1));
        }
        tot += x;
      }
      return (tot + parseInt(tz.charAt(8))) % 10 === 0;
    },
    maxLength(valueName, maxLength) {
      if (this.formData[valueName].length > maxLength) {
        this.formData[valueName] = this.formData[valueName].slice(0, maxLength);
      }
    },
    required(value) {
      return !!value;
    },
    getSchoolName(semelMosad) {
      const foundSchool = this.matchingSchools.find(
        school => school.semelMosad == semelMosad
      );
      if (foundSchool) {
        return foundSchool.schoolName;
      }
      return '';
    },
    async registerStudent() {
      let res = null;
      if (this.isEditMode) {
        const studentObjId = this.$store.getters['teacher/getEditStudent']
          .studentObjId;
        const { firstName, lastName, semelMosad, classNumber, classGrade } = this.formData;
        res = await teacherService.updateStudent(
          { firstName, lastName, semelMosad, classNumber, classGrade, studentObjId },
          this.$store.getters.getToken
        );
      } else {
        res = await teacherService.registerStudent(
          {
            ...this.formData,
            schoolName: this.getSchoolName(this.formData.semelMosad)
          },
          this.$store.getters.getToken
        );
      }
      if (res) {
        this.handleAjaxRes(res);
        return;
      }
      this.success = false;
    },
    handleAjaxRes(res) {
      // console.log({ res });
      if (res.data) {
        this.success = res.data.success;
        if (res.data.success) {
          this.$store.commit('teacher/setSearchedTz', this.formData.studentTz);
          this.$store.commit('teacher/setSelectedStudent', res.data.student);
          // this.dialog(await this.$t('registerStudentSuccessful'));
          this.resetForm();
          if (this.isEditMode) {
            this.$router.back();
          } else {
            this.$router.push(
              '/teacher/new-student/edit-profile-form?section=0'
            );
          }
          return;
        }
        this.err = res.data.errorCode;
      }
      this.success = false;
    },
    resetForm() {
      this.formData = {
        firstName: '',
        lastName: '',
        studentTz: null,
        semelMosad: null,
        classGrade: null,
        classNumber: null
      };
      this.touched = {
        firstName: false,
        lastName: false,
        studentTz: false,
        semelMosad: false,
        classNumber: false,
        classGrade: false
      };
      this.$store.commit('teacher/setEditStudent', {
        firstName: '',
        lastName: '',
        semelMosad: null,
        classNumber: null,
        classGrade: null,
        studentObjId: null
      });
    },
    currentSchools() {
      const currentSchoolsList = this.schoolsList;
      let schoolsToShow = [];
      for (const schoolFromTeacherList of currentSchoolsList) {
        const school = this.semelMosadList.find(
          schoolObj => schoolObj.semelMosad == schoolFromTeacherList.semelMosad
        );
        if (school) {
          schoolsToShow.push({
            semelMosad: school.semelMosad,
            schoolName: school.schoolName
          });
        } else {
          schoolsToShow.push({
            semelMosad: schoolFromTeacherList.semelMosad,
            schoolName:
              schoolFromTeacherList.schoolName ||
              schoolFromTeacherList.semelMosad
          });
        }
      }
      this.matchingSchools = schoolsToShow;

      // this.matchingSchools = _.intersectionWith(
      //   this.semelMosadList,
      //   currentTeacherSemeliMosad,
      //   (mosadFromFullList, mosadFromTeacherList) => {
      //     let result =
      //       mosadFromFullList.semelMosad === mosadFromTeacherList.semelMosad ||
      //       mosadFromFullList.semelMosad === mosadFromTeacherList.schoolId;
      //     return result;
      //   }
      // );
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/style.scss';

.form-container {
  padding-top: 2em;
  padding-right: 2em;
  color: $color-blue;
  width: 90%;
  text-align: right;
  direction: rtl;

  .form-title {
    margin-bottom: 2em;
    @media (max-width: 1366px) {
      margin-bottom: 0.8em;
    }
    font-size: 40px;
    font-family: $font-main;
    margin-top: 1em;
    font-weight: 600;
    color: $color-blue;
  }
  .form-subtitle {
  }
  .selection-option {
    :hover ::selection {
      background: $color-beige;
    }
  }
  .long-selection {
    width: 20em;
    height: 2.7em;
    // background: white;
    padding: 0.5em;
    &.custom-select {
      padding: 0;
    }
  }
  .short-selection {
    width: 9em;
    height: 2.7em;
    // background: white;
    padding: 0.5em;

    &.custom-select {
      padding: 0;
    }
  }

  .custom-select {
    z-index: 1000;
  }

  .top-marg {
    margin-top: 2em;
  }
}
.right-marg {
  margin-right: 2em;
}
.search-btn {
  height: 44px;
  width: 110px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  outline: none;
  img {
    width: 12px;
    margin: 4px;
  }

  &.cancel {
    margin-right: 5vw;
    color: $color-blue;
    border-color: $color-blue;
  }

  &.disabled {
    background-color: $color-lightblue;
    cursor: auto;
  }
}

.my-validation-text {
  font-family: $font-main;
  font-size: 16px;
  text-align: left;
  color: #ff0000;
  visibility: hidden;
}

.shown {
  visibility: visible;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
  ::placeholder {
    color: $color-beige;
  }
}
select{
  background-image: url("data:image/svg+xml;utf8,<svg fill='rgb(243,213,189)' height='2.7em' viewBox='0 0 24 24' width='40' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  width: 10em;
  height: 2.7em;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}
select .long-selection{
  width: 20em;
}
select .class-selection{
  width: 5em;
}
</style>

<style lang="scss">
@import '@/assets/scss/style.scss';
.register-teacher {
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
}

.selection-option {
  // background: $color-beige;
  color: $color-blue;
  select.decorated option:hover {
    box-shadow: 0 0 10px 100px $color-beige inset;
  }
  select:focus > option:checked {
    background: $color-beige !important;
  }
}
</style>
