<template>
  <div>
    <div class="finish-title">
      {{ mode === 'edit' ? $t('add') : '' }} {{ $t('moreComments') }}
    </div>
    <div
      v-if="mode === 'edit'"
      class="placeholder textarea-container"
    >
      <textarea
        v-model="commentText"
        :placeholder="$t('freeText')"
        class="textarea"
        cols="30"
        rows="10"
        maxlength="500"
      />
    </div>
    <div
      v-if="mode === 'view'"
      class="comment-text"
    >
      {{ commentText ? commentText : '' }}
    </div>
  </div>
</template>

<script>
import teacherService from '@/services/teacher-service';

export default {
  props: {
    mode: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      commentText: null
    };
  },
  created() {
    let studentObj = this.$store.getters['teacher/getSelectedStudent'];
    if (studentObj.formData) {
      if (studentObj.formData.teacherComment) {
        this.commentText = studentObj.formData.teacherComment;
      }
    }
  },
  methods: {
    async updateComments() {
      //send data of textarea
      // console.log('leave');

      const res = await teacherService.updateComments(
        {
          studentObjId: this.$store.getters['teacher/getSelectedStudent']._id,
          teacherComment: this.commentText
        },
        this.$store.getters.getToken
      );
      if (res) {
        if (res.data) {
          if (res.data.success) {
            this.$store.commit('teacher/setSelectedStudent', res.data.student);
          }
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/style.scss';

.textarea {
  resize: none;
  width: 100%;
  color: $color-blue;
  background-color: #fff;
  text-align: right;
  direction: rtl;
  padding-top: 1em;
  padding-right: 5px;
  margin-top: 5px;
}

.comment-text {
  margin-top: 1em;
}
</style>
